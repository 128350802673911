<template>
    <div>
        <TicketDrawerCreate />
        <TicketDrawerView />
    </div>
</template>

<script>
import mybases from "./store/index.js"
import TicketDrawerCreate from "./components/TicketDrawerCreate.vue";
import TicketDrawerView from "./components/TicketDrawerView.vue";
export default {
    components: {
        TicketDrawerCreate,
        TicketDrawerView
    },
    created() {
        if (!this.$store.hasModule("mybases"))
            this.$store.registerModule("mybases", mybases)
    }
}
</script>